
import * as React from "react"
import "./layout.css"
const Layout = ({ children }) => {
  return (
    <>
      <div
      >
        <main>{children}</main>
       <footer
          style={{
           
            textAlign: `center`,
          }}
        >
         © 2007 - {new Date().getFullYear()} &middot;DOSUGRU.NET  |  <a href="https://dosugru.net/dosugru.atom" class="rss">RSS</a>
        </footer>
      </div>
    </>  
  )
}
export default Layout
