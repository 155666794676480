import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ description, title, children }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            description
            author
            imageOG
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <>
      <link rel="canonical" href="https://dosugru.net/"/>
      <link rel="alternate" href="https://dosugru.net/" hreflang="ru"/>
      <link rel="amphtml" href="https://dosugru.net/amp/"/>
      <link rel="apple-touch-icon" href="/apple-touch-icon.png"/>
	    <link rel="icon" href="/favicon.svg" type="image/svg+xml"/>
	    <link rel="icon" href="/favicon.ico" sizes="32x32"/>
      <title>{defaultTitle ? `${title} - ${defaultTitle}` : title}</title>
      <meta property="og:locale" content="ru_RU"/>
      <meta property="og:site_name" content="dosugru.net"/>
      <meta property="og:description" content={metaDescription}/>
      <meta property="og:image" content={site.siteMetadata.imageOG}/>
      <meta property="og:url" content="https://dosugru.net/"/>
      <meta property="og:type" content="website"/>
      <meta property="og:title" content={defaultTitle ? `${title} - ${defaultTitle}` : title}/>
      <meta name="twitter:image" content={site.siteMetadata.imageOG}/>
      <meta name="twitter:description" content={metaDescription}/>
      <meta name="twitter:title" content={defaultTitle ? `${title} - ${defaultTitle}` : title}/>
      <meta name="twitter:creator" content={site.siteMetadata?.author || ``}/>
      <meta name="twitter:site" content={site.siteMetadata?.author || ``}/>
      <meta name="twitter:card" content="summary_large_image"/>
      <meta itemprop="image" content={site.siteMetadata.imageOG}/>
	    <meta itemprop="description" content={metaDescription}/>
	    <meta itemprop="name" content={defaultTitle ? `${title} - ${defaultTitle}` : title}/>
	    <meta name="description" content={metaDescription}/>
      <link rel="alternate" type="application/atom+xml" href="https://dosugru.net/dosugru.atom" title="Dosug (Досуг) в России | Мы работаем - чтобы Вы отдыхали!"/>
      <script type="application/ld+json">
  {`
    {
        "@context": "http://schema.org",
        "@type": "Article",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "https://dosugru.net/"
        },
        "headline": "Dosug (Досуг) в России | Мы работаем - чтобы Вы отдыхали!",
        "image": {
          "@type": "ImageObject",
          "url": "https://dosugru.net/images/dosug-ru-net.jpg",
          "height": 630,
          "width": 1200
        },
        "author": {
          "url": "https://dosugru.net/",
          "@type": "Organization",
          "name": "DosugRu.Net"
        },
        "publisher": {
          "@type": "Organization",
          "name": "DosugRu.Net",
          "logo": {
            "@type": "ImageObject",
            "url": "https://dosugru.net/images/amp.png",
            "width": 325,
            "height": 60
      }}}  
  `}
</script>
      {children}
    </>
  )
}

export default Seo
