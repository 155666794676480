import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const IndexPage = ({ data }) => {
    const {
        srcWebp,
        srcSetWebp,
        srcSet,
        src,
        presentationHeight,
        presentationWidth,
        } = data.imageSharp.fluid;
        return (
          <Layout>
          <div id="wrapper">
          <div id="main">
            <div class="inner">
              <div id="container01" class="container default">
                <div class="wrapper">
                  <div class="inner">
                    <div id="image01" class="image"><span class="frame">
    <amp-img
        src={srcWebp}
        srcset={srcSetWebp}
        width={presentationWidth}
        height={presentationHeight}
        alt="Досуг" 
        title="Портал Dosug"
        layout="intrinsic"
      >
        <div fallback>
          <amp-img
            src={src}
            srcset={srcSet}
            width={presentationWidth}
            height={presentationHeight}
            alt="Досуг" 
            title="Портал Dosug"
            layout="fill"
          />
          </div>
      </amp-img></span></div>
							<p id="text11">Приветствуем Вас нашем портале «Досуг в России»!</p>
							<h1 id="text02" class="style1">Вход на Dosug в России</h1>
							<ul id="buttons01" class="buttons">
								<li><a href="/cgi-bin/net.pl" class="button n01">Вход 18+</a></li>
							</ul>
							<p id="text03" class="style3">Сохраните или запомните эту страницу, чтобы всегда иметь доступ к рабочей версии основного сайта.</p>
							<ul id="icons01" class="icons">
              <li>
									<a a class="n01" href="https://twitter.com/DosugczOfficial" aria-label="Присоединяйтесь к нам в Twitter">
									<svg aria-labelledby="icon-title" viewBox="0 0 40 40" display="none" width="0" height="0" xmlns="http://www.w3.org/2000/svg">
                      <path d="M30.1,4h5.4L23.7,17.6L37.6,36H26.7l-8.5-11.2L8.5,36H3.1l12.6-14.5L2.4,4h11.1l7.7,10.2L30.1,4z M28.2,32.8h3L11.9,7.1H8.7 L28.2,32.8z" fill="#FFFFFF"></path>
                    </svg><span class="label">X</span></a>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<p class="style4">Пожалуйста, обратите внимание, что на этом ресурсе доступна только Кнопка, которая перенаправляет пользователя на случайный веб-адрес. Мы надеемся, что сотрудники государственных организаций не будут совершать никаких незаконных действий в отношении этого ресурса.</p>
				<hr class="style1"></hr>
				<p class="style5">Добро пожаловать на самый горячий портал о досуге в России! Если Вы находитесь на нашем портале, значит ищите страстные, неуёмные и горячие удовольствия – Вы обратились по адресу!</p>
				<hr id="divider02"></hr>
				<p class="style5">Наш сайт создан специально для того что бы Вы без труда могли организовать свой интимный досуг. Воспользовавшись удобным портфолио, Вы легко сможете выбрать путану на свой вкус. С помощью нашего ресурса Вы сможете удовлетворить все свои интимные желания и воплотить в жизнь самые сокровенные фантазии. Наши индивидуалки позаботятся о том, что бы Вы остались довольны, вознеся Вас к вершинам экстаза.</p>
				<hr id="divider03"></hr>
				<p class="style5">В наше время предоставлении сексуальных услуг за деньги не являются чем-то предосудительным. Многие мужчины достаточно регулярно пользуются подобными услугами. С развитием Интернет-технологий выезд за проституткой на улицу перестал быть актуальным. К тому же, проделав такой рейд, Вы вряд ли найдете действительно хорошую индивидуалку.</p>
				<hr id="divider04"></hr>
				<p class="style5">Мы идем на поводу Ваших желаний! И если Вы поклонник экзотического секса или просто устали от классики, мы рады представить Вашему вниманию секс знакомства с мужчинами и юношами по вызову и даже с транссексуалами. Наши девочки предлагают услуги на любой вкус: оральный и анальный секс, эротический массаж, золотой дождь, лесби.</p>
				<hr id="divider05"></hr>
				<p class="style5">Так же на нашем портале Вы найдете подробную информацию о всех лучших массажных салонах города. Надежность нашей работы проверенна временем и не одной сотней клиентов. Мы заботимся о качестве предоставляемых услуг и сохранении Вашей конфиденциальности.</p>
				<hr id="divider01"></hr>
				<p class="style5">Добро пожаловать на Развлекательный портал «Досуг в России»!</p>
			</div>
		</div>
	</div>
  </Layout>
)
}
export const Head = () => <Seo title="Dosug (Досуг) в России | Мы работаем - чтобы Вы отдыхали!">
<meta name="yandex" content="noindex, nofollow"/></Seo>
export default IndexPage
export const query = graphql`
query MyQuery {
  imageSharp {
    fluid(
      maxHeight: 44
      maxWidth: 400
      toFormat: AVIF
      srcSetBreakpoints: [40, 80, 120, 160, 200, 240, 280, 320, 360, 400,440,480,520,560,600,640,680,720,760,800]
    ) {
      srcWebp
      srcSetWebp
      srcSet
      src
      presentationHeight
      presentationWidth
    }
  }
}
`